import { Filmomtale } from '@filmweb/sanity-types';
import ButtonRow from 'components/framework/ButtonRow';
import MessageBanner from 'components/framework/MessageBanner';
import SMaxWidthContainer from 'components/framework/SMaxWidthContainer';
import ShowAllShows from 'components/frontpage/cinemaprogram/ShowAllShows';
import ShowsToday from 'components/frontpage/cinemaprogram/ShowsToday';
import ShowXShows from 'components/frontpage/cinemaprogram/ShowXShows';
import ShowXCulture from 'components/frontpage/culture/ShowXCulture';
import FilmwebContentSection from 'components/frontpage/filmwebElements/FilmwebContentSection';
import HeroArticle from 'components/frontpage/heroarticle/HeroArticle';
import OtherArticles from 'components/frontpage/otherarticles/OtherArticles';
import ArticleWithIconContainer from 'components/frontpage/specialshows/ArticleWithIconContainer';
import TextListContainer from 'components/frontpage/textlists/TextListContainer';
import { setDefaultCacheHeaders } from 'lib/server/cache';
import { FilmwebFrontpageContent, fwpakkeQuery, getFilmwebFilmomtale, getFilmwebFrontpageContent, getServerData } from 'lib/server/sanity';
import IFrontpage from "lib/types/Frontpage";
import { DocumentType_MOVIE, ICommonAppData, IMovieArticle } from 'lib/types/sanity';
import { uniq } from "lodash";
import { groq } from 'next-sanity';
import { GetServerSideProps, GetServerSidePropsContext } from 'next/types';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

//import styles from '../styles/Home.module.css'

//#region [Props]
type FrontPageProps = {
    frontpage: IFrontpage;
    movies?: Filmomtale[];
    appData: ICommonAppData;
    filmwebFrontpageContent?: FilmwebFrontpageContent;
};

//#endregion

type FrontPageWithPreview = {
    published: IFrontpage;
    preview: IFrontpage;
};

//#region [Other]getServerSideProps
export const getServerSideProps: GetServerSideProps = getServerData(async (context) => {
    setDefaultCacheHeaders(context as GetServerSidePropsContext);

    const frontpageData = await fwpakkeQuery<IFrontpage | FrontPageWithPreview>(context.preview ? FRONTPAGE_QUERY_PREVIEW : FRONTPAGE_QUERY, undefined, !context.preview);
    const edis: Set<string> = new Set<string>();

    let data: IFrontpage | undefined;
    if (context.preview) {
        const fpData = frontpageData as FrontPageWithPreview;
        data = fpData?.preview ?? fpData?.published;
    } else {
        data = frontpageData as IFrontpage;
    }

    if (data?.topItem?.document?._type === DocumentType_MOVIE) {
        edis.add((data!.topItem.document as IMovieArticle).edi.current);
    }

    data?.otherarts?.forEach(otherArt => {
        if (otherArt?._type === DocumentType_MOVIE) {
            edis.add((otherArt as IMovieArticle).edi?.current);
        }
    });

    data?.textLists?.forEach(textList => {
        textList?.listItems?.forEach(listItem => {
            if (listItem?._type === DocumentType_MOVIE) {
                edis.add((listItem as IMovieArticle).edi?.current);
            }
        });
    });

    const fwMoviesPromise = getFilmwebFilmomtale(Array.from(edis));
    const fwContentPromise = getFilmwebFrontpageContent();
    await Promise.all([fwMoviesPromise, fwContentPromise]);
    console.debug("Frontpage done");
    return {
        props: {
            frontpage: data,
            movies: await fwMoviesPromise,
            filmwebFrontpageContent: await fwContentPromise
        }, // will be passed to the page component as props

    }
});
//#endregion

//#region [Styles]
type SSectionContainerProps = {
    $order?: number;
    $fullBleed?: boolean;
}

const SSectionContainer = styled(SMaxWidthContainer) <SSectionContainerProps>`
	--spacer_size: 50px;


	padding-left: 10px;
	padding-right: 10px;

	${props => props.$fullBleed && css`
		padding-left: 0;
		padding-right: 0;
	`}


	@media screen and (max-width: 640px){
		--spacer_size: 30px;
		${props => props.$order && css`order: ${props.$order};`}
		${props => props.$order === 3 && css`margin-top: var(--spacer_size);`}
	}

	&:nth-of-type(2) { // the n-th div (:nth-of-type(1) is the ad)
		--spacer_override: 0;
	}

`;
//#endregion

//#region [Other]
const FRONTPAGE_QUERY_FILTER_PROD = groq`[_type == "fwpakkeFrontpage" && !(_id in path("drafts.**"))][0]`;
const FRONTPAGE_QUERY_FILTER_PREVIEW = groq`[_type == "fwpakkeFrontpage" && (_id in path("drafts.**"))][0]`;

const FRONTPAGE_QUERY_DATA = groq`
 {
	...,
	"topItem": @.frontpageFields.topItem {
		document->{
			...,
			image->,
			frontpageImage->,
			titleOverride
		},
		subtitle
	},
	"textLists": @.frontpageFields.textLists[] {
		...,
		"listItems": @.listItems[] {
			_type == "reference" => @-> {
			   	...,
				_type == "downloadableFile" => ^ {
					"file":@->file.asset->
			  	}
			},
			_type == "listExtLink" => @ {
			  ...
			}
		}
	},
	"articleListWithIcon": @.frontpageFields.articleListWithIcon[] {
		_type == "reference" => @-> {
			_type,
			_id,
				slug,
				title,
				frontpageText,
				icon,
				customIcon
		  },
		_type == "newsRefWithIcon" => {
			icon,
			customIcon,
			"title": newsRef->title,
			"ingress": newsRef->ingress,
			"slug": newsRef->slug,
			"_type": newsRef->_type,
			"_id": newsRef->_id,
		}
	},
	"otherarts": @.frontpageFields.otherarts[]-> {
		...,
		image->
	}

}
`;

const FRONTPAGE_QUERY = groq`
	*${FRONTPAGE_QUERY_FILTER_PROD}${FRONTPAGE_QUERY_DATA}
`;

const FRONTPAGE_QUERY_PREVIEW = groq`{
	"published": *${FRONTPAGE_QUERY_FILTER_PROD}${FRONTPAGE_QUERY_DATA},
	"preview":*${FRONTPAGE_QUERY_FILTER_PREVIEW}${FRONTPAGE_QUERY_DATA},
}`;

//#endregion

//#region [Styles]

//#endregion

//#region [Component]
export default function Frontpage({ frontpage, movies, appData, filmwebFrontpageContent }: FrontPageProps) {
    return (
        <>
            <MessageBanner banner={appData.menu?.messageBanner} />
            <ButtonRow buttonrowItems={appData.menu?.buttonrow} />
            {appData.settings.frontpageElements.map((element, elementIndex) => {
                let frontpageElement: ReactNode | null = null;
                let isFullBleed = false;
                switch (element.elementType) {
                    case "topItem":
                        if (frontpage?.topItem) {
                            frontpageElement = <HeroArticle topItem={frontpage.topItem} movies={movies} allowTitleOverride={appData.settings.canOverrideMovieTitles} />;
                            isFullBleed = true;
                        }
                        break;
                    case "textLists":
                        if ((frontpage?.textLists?.length ?? 0) > 0) {
                            frontpageElement = <TextListContainer textLists={frontpage.textLists!} movies={movies}/>;
                        }
                        break;
                    case "cinematoday":
                        frontpageElement = <ShowsToday settings={appData.settings} />;
                        break;
                    case "xshows":
                        frontpageElement = <ShowXShows settings={appData.settings} />;
                        break;
                    case "allshows":
                        frontpageElement = <ShowAllShows settings={appData.settings} />;
                        break;
                    case "articleListWithIcon":
                        if ((frontpage?.articleListWithIcon?.length ?? 0) > 0) {
                            frontpageElement = <ArticleWithIconContainer articleList={frontpage.articleListWithIcon!} />;
                        }
                        break;
                    case "otherarts":
                        if ((frontpage?.otherarts?.length ?? 0) > 0) {
                            frontpageElement = <OtherArticles articleList={frontpage.otherarts} fwFilms={movies} allowTitleOverride />
                        }
                        break;
                    case "xculture":
                        frontpageElement = <ShowXCulture settings={appData.settings} heading={appData.kulturProgram?.headingText} showMoreLink={appData.kulturProgram?.linkTekst} />
                        break;
                }
                // Only the first element should be above the ad on mobile:
                let order: number | undefined;
                if (elementIndex > 0) {
                    order = 2 + elementIndex;
                }
                return (frontpageElement ? <SSectionContainer key={element._key} $order={order} $fullBleed={isFullBleed}>
                    {frontpageElement}
                </SSectionContainer> : null);
            })}
            {appData?.settings?.frontpageFilmwebElements && <SSectionContainer $order={50}>
                <FilmwebContentSection filmwebElements={appData.settings.frontpageFilmwebElements} content={filmwebFrontpageContent} />
            </SSectionContainer>
            }

        </>
    )
}
//#endregion
