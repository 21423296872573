import styled from "styled-components";
import { IListExtLink, ITextList } from "lib/types/Frontpage";
import SSectionHeader from "components/framework/SSectionHeader";
import Link from "next/link";
import { DocumentType_MOVIE, getUrl, ICultureArticle, IFileArticle, IMovieArticle, INewsArticle } from "lib/types/sanity";
import { toRem } from "lib/client/fonts";
import { Filmomtale } from "@filmweb/sanity-types";

//#region [Styles]
const STextListContainer = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-bottom: var(--spacer_size, 0);

	@media screen and (min-width: 641px) {
		flex-direction: row;
		justify-content: center;
	}

	@media screen and (max-width: 640px) {
		padding: 10px 0;

		background-color: var(--color_section_bg_mobile, transparent);
		a, h1 {
			color: var(--color_section_text_mobile, var(--color_text, #000));
		}

	}
`;

type STextListLayoutProps = {
    listCount: number;
}

const STextListLayout = styled(TextList) <STextListLayoutProps>`

	@media screen and (min-width: 641px) {
		${props => `flex: 1 1 ${100 / props.listCount}%`}
	}
`

//#endregion

//#region [Props]
type TextListContainerProps = {
    textLists: ITextList[];
    movies?: Filmomtale[];
};
//#endregion

//#region [Component]
export default function TextListContainer({ textLists, movies }: TextListContainerProps) {

    return (<STextListContainer>
        {textLists.map(list => <STextListLayout listCount={textLists.length} key={list._key} textList={list} movies={movies} />)}
    </STextListContainer>);
}
//#endregion

//#region [Styles]
const STextList = styled.section`
	text-align: center;


	@media screen and (min-width: 641px) {
		padding: 0 15px;
		flex: 1 1 25%;
		max-width: 30%;

		&:not(:first-child) {
			border-left: 3px solid var(--color_textlists_border, var(--color_primary));
		}
		&:not(:last-child) {
			border-right: 3px solid var(--color_textlists_border, var(--color_primary));
		}
	}

`;

const STextListTitle = styled(SSectionHeader)`
	--color_local_border_override: var(--color_textlists_border);
`;

const SList = styled.ol`
	list-style: none;
	padding: 0;
	margin: 0 0 2em 0;

	font-size: ${toRem(20)};
	li {
		margin-bottom: 1em;
	}
`;

//#endregion


//#region [Props]
type TextListProps = {
    textList: ITextList;
    className?: string;
    movies?: Filmomtale[];
};

//#endregion
//#region [Component]
function TextList({ textList, className, movies }: TextListProps) {
    const listItems = textList?.listItems?.filter(li => !!(li?._id || li?._key));
    if ((listItems?.length ?? 0) === 0) {
        return null;
    }

    return <STextList className={className}>
        <STextListTitle>{textList.listTitle}</STextListTitle>
        <SList>
            {listItems.map(item => {
                let movie: Filmomtale | undefined;
                if (item._type === DocumentType_MOVIE) {
                    const filmItem = item as IMovieArticle;
                    movie = movies?.find(m => m.mainVersionEDI === filmItem.edi.current);
                }
                return <TextListItem key={(item._id ?? item._key) as string} item={item} movie={movie} />;
            })}

        </SList>
    </STextList>
}

type TextListItemProps = {
    item: ICultureArticle | IMovieArticle | INewsArticle | IFileArticle | IListExtLink;
    movie?: Filmomtale;
}

function TextListItem({ item, movie }: TextListItemProps) {
    const url = getUrl(item);
    if (!url) {
        console.warn("No url for", item);
        return null;
    }

    let title = movie?.title ?? item.title;
    if ('titleOverride' in item && item.titleOverride) {
        title = item.titleOverride as string;
    }

    return (<li>
        <Link href={url}>
            {title}
        </Link>
    </li>);
}
//#endregion