import { Filmomtale, ICustomImage } from "@filmweb/sanity-types";
import { CMSPicture, CMSPictureSource } from "components/images/Image";
import { ITopItem } from "lib/types/Frontpage";
import { DocumentType_MOVIE, FwPakkeImageDocument, IMovieArticle, getUrl } from "lib/types/sanity";
import Link from "next/link";
import { useMemo } from "react";
import styled, { css } from "styled-components";

//#region [Props]
type HeroArticleProps = {
	topItem?: ITopItem;
	movies?: Filmomtale[];
	allowTitleOverride?: boolean;
};
//#endregion

//#region [Component]
export default function HeroArticle({ topItem, movies, allowTitleOverride }: HeroArticleProps) {
	const filmomtale = useMemo<Filmomtale | undefined>(() => {
		if ((movies?.length ?? 0) > 0 && topItem) {
			if (topItem.document?._type === DocumentType_MOVIE) {
				const edi = (topItem.document as IMovieArticle).edi.current;
				return movies!.find(m => m.mainVersionEDI === edi);
			}
		}
	}, [topItem, movies]);

	if (!topItem?.document) {
		return null;
	}

	//const isFilmwebImage = !!filmomtale?.mainImage || !!filmomtale?.images?.[0];
	let isFilmwebImage = false;
	let image: FwPakkeImageDocument | ICustomImage | undefined = (topItem.document.frontpageImage as FwPakkeImageDocument ?? topItem.document.image as FwPakkeImageDocument);
	if (!image) {
		isFilmwebImage = true;
		image = (filmomtale?.mainImageV2) ?? (filmomtale?.imagesV2?.[0]);
	}

	let title: string = filmomtale?.title ?? topItem.document.title;
	if (allowTitleOverride && topItem.document.titleOverride) {
		title = topItem.document.titleOverride as string;
	}

	const url = getUrl(topItem.document);
	if (!url) {
		return null;
	}

	const altText = isFilmwebImage ? `Bilde fra '${filmomtale?.title}'` : ((image as FwPakkeImageDocument)?.altText ?? (image as FwPakkeImageDocument)?.title);

	return (
		<SArticle>
			<Link href={url} passHref legacyBehavior>
				<SArticleLink>
					{image && <STopImage isFilmweb={isFilmwebImage} image={image} fallbackWidth={640} fallbackHeightRatio={1.43} altText={altText ?? ""}>
						<CMSPictureSource media="(min-width: 641px)" sizes={[1440]} heightRatio={0.35} />
					</STopImage>
					}
					<SArticleHeader $hasImage={!!image}>
						<div>
							<SArticleTitle>{title}</SArticleTitle>
							{topItem.subtitle && <SArticleSubTitle>{topItem.subtitle}</SArticleSubTitle>}
						</div>
					</SArticleHeader>
				</SArticleLink>
			</Link>
		</SArticle >
	);
}
//#endregion

//#region [Styles]
const SArticle = styled.article`
	@media screen and (min-width: 641px) {
		border-bottom: 7px solid var(--color_toparticle_border, var(--color_primary));
		margin-bottom: var(--spacer_size, 0);
	}
	position: relative;


	@media screen and (max-width: 640px) {
		margin-bottom: var(--spacer_override, var(--spacer_size, 0));
	}
`;

const SArticleLink = styled.a`
	display: block;
	&:hover {
		h1, p {
			text-decoration: underline;
		}
	}
`;

const STopImage = styled(CMSPicture)`
	/* TODO: Do we want this?
	@media screen and (max-width: 640px) {
		position: fixed;
		width: 100vw;
		z-index: -1;
		top: 0;
	}*/

	display: block;
	img {
		display: block;
		width: 100%;
	}
`;

type SArticleHeaderProps = {
	$hasImage: boolean;
};

const SArticleHeader = styled.header<SArticleHeaderProps>`
	display: flex;
	flex-wrap: nowrap;
	align-items: stretch;

	padding: 12px;

	background-color: rgba(0,0,0, 0.8);
	color: #ffffff;
	text-align: center;

	position: absolute;
	width: 90%;
	left: 5%;
	bottom: 10%;

	${props => !props.$hasImage && css`
		position: static;
		margin-left: 5%;
	`}

	@media screen and (min-width: 641px) {
		text-align: left;
		border: none;
		bottom: 0;
		left: 0;
		width: 100%;

		padding: 48px 24px 24px 24px;
		background-color: transparent;
		background-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.8) 100%);

		${props => !props.$hasImage && css`
			position: static;
			margin-left: 0;
		`}
	}

	>div {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;

		@media screen and (min-width: 641px) {
			padding-left: 8px;
			margin-left: 8px;
			border-left: 10px solid var(--color_toparticle_border, var(--color_primary));;
			padding-left: 24px;
			margin-left: 24px;
		}
	}
`;

const SArticleTitle = styled.h1`
	font-size: 1.875rem;
	margin: 0;
	line-height: 1;
	width: 100%;

	@media screen and (min-width: 641px) {
		font-size: 3rem;
	}
`;

const SArticleSubTitle = styled.p`
	font-size: 1.175rem;
	margin: 0;
	font-weight: normal;
	width: 100%;
	line-height: 1.3;
	@media screen and (min-width: 641px) {
		font-size: 1.5625rem;
	}

`;

//#endregion